<template>
  <Modal 
      v-if="addMembersModal.visible"
      :modal="addMembersModal"
      @closeModal="addMembersModal.visible = false"
      :cssClasses="{'is-full-vw': true,'is-full-height': true}"  
  >
      <template v-slot:header>
          ADD MEMBER TO GROUP
      </template>
      <template v-slot class="is-full-width">
          <div class="has-background-dark has-text-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2">Attention: Members have FULL CONTROL over ALL YOUR ITEMS!</h1>

              <input class="input" type="text" placeholder="username" v-model="addMembersModal.newMember">
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" :disabled="!(addMembersModal.newMember?.length > 0)" @click="handleAddMember">ADD MEMBER</button>
          <button class="button has-background-pink-1 has-text-light" @click="addMembersModal.visible = false">CANCEL</button>
      </template>
  </Modal>

  <div class="config is-flex is-flex-direction-column is-justify-content-center">

    <h1 class="m-3"></h1>

      <p>Hallo, {{$store.state.data.username}}!</p>

      <h3>You are member of these groups:</h3>
      <div class="is-flex is-flex-direction-row is-justify-content-center is-overflow-x-scroll is-overflow-y-hidden mb-2 invisible-scrollbar">
        <div class="m-1" v-for="group in $store.state.data.usergroups" :key="group">
          <Pill class="userpill" :tag="group" hideDeleteButton="true"/>
        </div>
      </div>

      
      <h3>Your members:</h3>
      <div class="is-flex is-flex-direction-row is-justify-content-center is-overflow-x-scroll mb-2 invisible-scrollbar">
        <div class="m-1" v-for="member in $store.state.data.groupmembers" :key="member">
          <Pill class="userpill" :tag="member" @onDeleteClick="handleRemoveMember(member)"/>
        </div>
      </div>
      <div>
        <button class="button is-third-width m-1" @click="addMembersModal.visible = true">ADD MEMBERS</button>
      </div>

      
      <h3 class="mt-2">Your Storage Facilities: </h3>
    
      <div class="is-full-width is-flex is-flex-direction-row is-justify-content-center ">
        <div class="is-flex is-flex-direction-row is-justify-items-center m-2 is-overflow-x-scroll invisible-scrollbar">
          <div v-for="storage in rootStorages" :key="storage.id">
            <Pill class="m-1" :tag="storage.name" @click="$router.push('/Detail?id='+storage.id)" hideDeleteButton="true"/> 
          </div>
        </div>
      
      </div>
      
      <div>
        <button class="button is-third-width m-1" @click="$router.push('/AddDing?showGeolocation=true')">ADD STORAGE</button>
      </div>
        
<hr>

    <div class="checkbox">
      <label>
      <input @click="handhandleRememberStorageChange" type="checkbox" :checked="rememberLastStorageInAdd">
        remember storage location of item
      </label>
    </div>

<hr>
    <div class="is-flex is-flex-direction-column is-align-items-center m-2 is-overflow-x-scroll invisible-scrollbar">
      <div>Your dingsda2mex cloud printer</div>
      <input class="input is-half-width" ref="cloudprinter" @change="handleCloudPrinterUpdate" type="text" placeholder="printer id">
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from "vuex";
import { nextTick, onMounted } from '@vue/runtime-core';

import Pill from '@/components/Template/Pill.vue';
import Modal from '@/components/Template/Modal.vue';

export default {
  name: 'Config',
  components:{Pill,Modal},
  setup(){

      const store = useStore();

      const addMembersModal = ref({visible:false,newMember:null})

      const rootStorages = ref([]);
      const rememberLastStorageInAdd = ref(store.state.ui.config.rememberLastStorageInAdd);
      
      const cloudprinter = ref(null);

      const handhandleRememberStorageChange = (e)=>{
        nextTick(()=>{
          store.dispatch('setConfigValue',{key:'rememberLastStorageInAdd',value:e.target.checked})
        }) 
      }

      const handleRemoveMember = async (e)=>{
        let done = await store.dispatch('deleteMember',{id:e});
        if (done){store.dispatch("testconnection")} 
      }

      const handleAddMember = async ()=>{
        let done = await store.dispatch('addMember',{id:addMembersModal.value.newMember});
        if (done){addMembersModal.value.newMember = null;store.dispatch("testconnection")}
      }

      const handleCloudPrinterUpdate = () =>{
        console.log("cloudprinter update",cloudprinter.value.value);
        store.dispatch('setConfigValue',{key:"cloudprinter",value:cloudprinter.value.value})
      }

      onMounted(async()=>{
        rootStorages.value = await store.dispatch('getAllRootContainers');
        cloudprinter.value.value = store.state.ui.config.cloudprinter; 
      })
      
      return {
        handhandleRememberStorageChange,
        handleRemoveMember,
        rememberLastStorageInAdd,
        rootStorages,
        addMembersModal, handleAddMember,
        cloudprinter,handleCloudPrinterUpdate
      }
  }
}
</script>

<!--
Displays the RootLocation of a given item, by using server endpoint
-->

<template>


    <div v-if="rootLocation">
        <Pill class="m-1 geoLocationTag" v-if="rootLocation && rootLocation['location.placename']" :tag="rootLocation['location.placename']" hideDeleteButton="true"/> 
        <Pill class="m-1 geoLocationTag" v-if="rootLocation && !rootLocation['location.placename'] && rootLocation['location.id']" :tag="rootLocation['location.id']" hideDeleteButton="true"/> 
    </div>

</template>

<script>

import Pill from '@/components/Template/Pill.vue'
import { computed, onMounted,ref, watch } from '@vue/runtime-core';
import { useStore } from "vuex";

export default {
  name: "RootLocationPill",
  props: ["item"],
  emits: [],
  components: { Pill },
  setup(props) {
    const store = useStore();

    const rootLocation = ref(null);

    const fillRootLocation = () => {
        // TODO: spare us talking to the server if we already have props.item.location.geoJSON and placename provided.
        store.dispatch('getItemRootContainer',props.item.id).then((res)=>{
            rootLocation.value = res;
        })
    }

    onMounted(()=>{
      fillRootLocation();
    })

    watch(computed(()=>props.item),fillRootLocation)

    return {rootLocation};
  },
};
</script>

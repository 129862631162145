<template>
   <div class="tags is-flex-wrap-nowrap has-addons" :class="inputclass" @click.stop.prevent="$emit('onTagClick',$event,tag)">
          <span :class="{'tag taghead is-darkgrey':true,'solotag':tag.split(':').length < 2}" >{{ tag.split(":")[0] }}</span>
          <span v-if="tag.split(':')[1]" class="tag tagtail is-dark has-text-white has-text-weight-bold">{{ tag.split(":")[1] }}</span>
         <a v-if="!hideDeleteButton" class="tag is-delete is-dark has-font-small " @click.stop.prevent="$emit('onDeleteClick',$event,tag)"></a>
   </div>
</template>

<script>


export default {
  name: "Pill",
  props: ["tag", "hideDeleteButton","inputclass"],
  emits: ["onTagClick","onDeleteClick"],
  components: { },
  setup() {
    return {};
  },
};
</script>
